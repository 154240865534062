import React, {Component} from 'react';
import {Row, Col} from 'react-bootstrap';
import ProjectCard from "./Project";

class ProjectsManual extends Component {
    render() {
        return (
            <div>
                <div className="lead mt-5">Projekty</div>
                <Row className="justify-content-center">
                    <ProjectCard title="AXILARA" url="https://axilara.xyz" description="Discord Bot" />
                    <ProjectCard title="NašeFauna" url="https://nasefauna.cz" description="Inzertní portál" />
                    <ProjectCard title="Fantasy World" url="https://fantasyworldtrpg.cz" description="TRPG hra" />
                    <ProjectCard title="HUGOKNIVES" url="https://hugoknives.cz" description="Nože na zakázku" />
                    <ProjectCard title="HEX Clock" url="https://steamcommunity.com/sharedfiles/filedetails/?id=2708625628" description="Doplněk pro Wallpaper Engine" />
                </Row>
            </div>
        );
    }
}

export default ProjectsManual;