import React, { useState, useEffect } from 'react';
import {Row, Col} from 'react-bootstrap';
import ProjectCard from "./Project";

function ProjectList() {
    const [projects, setProjects] = useState([]);

    const fetchProjects = async () => {
        fetch('https://api.lexikonn.eu/getdata/getprojects.php')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Data received:', data);
                setProjects(data);
            })
            .catch(error => console.error('Chyba při načítání dat:', error));
    };

    useEffect(() => {
        fetchProjects();
    }, []);

    return (
        <div>
            <div className="lead mt-5">Projekty</div>
            <Row className="justify-content-center">
                {
                    projects ? projects.map(project => (
                        <ProjectCard title={project[1]} url={project[2]}/>
                )) : "Nic nenalezeno"
            }
            </Row>
        </div>
    );
}

export default ProjectList;
