import React, { useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

function YTCount() {
    const [subs, setSubs] = useState('');
    const [videoData, setVideo] = useState({});

    useEffect(() => {
        fetch('https://api.lexikonn.eu/youtube/getsubs.php')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Subscribers Data received:', data);
                setSubs(data.subs);
            })
            .catch(error => console.error('Chyba při načítání dat:', error));

        fetch('https://api.lexikonn.eu/youtube/getatestvideo.php')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                console.log('Video Data received:', data);
                setVideo(data);
            })
            .catch(error => console.error('Chyba při načítání dat:', error));
    }, []);
    const url = "https://www.youtube.com/watch?v=" + (videoData?.resourceId?.videoId || '')
    return (
        <Row className="bshadow">
            <Col xl={6}>
                <a className="no-a" target="_blank" href={url}>
                    <div className="fs-3">{videoData.title}</div>
                    {videoData ? (
                    <img className="img-fluid" src={videoData?.thumbnails?.standard?.url} alt={videoData.title} />
                ) : (
                    "Žádná data"
                )}</a>
            </Col>
            <Col className="big justify-content-center align-content-center" xl={6}>
                <i className="bi ytred bi-youtube"></i> {subs ? subs + " subs" : 'Načítám...'}
            </Col>
        </Row>
    );
}

export default YTCount;
