import React, {Component} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap-icons/font/bootstrap-icons.css';

class SideEffects extends Component {
    render() {
        return (
            <div>
                <div className="bubble bubble1 my-3 lead"><i class="bi bi-code-slash"></i>&nbsp;&nbsp;Programmer</div>
                <div className="bubble bubble2 my-3 lead"><i class="bi bi-circle-square"></i>&nbsp;&nbsp;3D Graphic designer</div>
                <div className="bubble bubble3 my-3 lead"><i class="bi bi-controller"></i>&nbsp;&nbsp;Game modder</div>
            </div>
        );
    }
}

export default SideEffects;