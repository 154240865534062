import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ProjectsManual from './components/projects2'
import Main from './components/main'
import YTCount from "./components/yt";
import {Container} from 'react-bootstrap';
import Skills from './components/skills';
import Contact from './components/contact';
import ProjectList from "./components/projects";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <Container className="mt-5 pb-5 text-center">
          <Main />
      </Container>
      <div className="text-center ytbanner p-5">
          <YTCount/>
      </div>
      <Container className="mt-5 pb-5 text-center">
          <ProjectList />
          <Skills />
          <Contact />
      </Container>
  </React.StrictMode>
);

reportWebVitals();
