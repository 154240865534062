import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';

function Footer() {
    return (
        <footer className="light mt-5 text-dark py-4">
            <Container>
                <Row>
                    <Col lg="3" md="6" className="text-center">
                        <a href="https://www.youtube.com/@lexikonn" target="_blank"><i
                            className="bi bi-youtube"></i> YouTube Lexikonn</a>
                    </Col>
                    <Col lg="3" md="6" className="text-center">
                        <a href="https://www.youtube.com/@PixelVibeMusic" target="_blank"><i
                            className="bi bi-youtube"></i> YouTube PixelVibe</a>
                    </Col>
                    <Col lg="3" md="6" className="text-center">
                        <a href="https://www.tiktok.com/@lexikonn" target="_blank"><i
                            className="bi bi-tiktok"></i> TikTok</a>
                    </Col>
                    <Col lg="3" md="6" className="text-center">
                        <a href="mailto:thatsme@lexikonn.cz"><i class="bi bi-envelope-at-fill"></i> thatsme@lexikonn.cz</a>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;
