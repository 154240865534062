import React, { Component } from 'react';
import Col from 'react-bootstrap/Col';

class ProjectCard extends Component {
    render() {
        const { title, description, url } = this.props;

        return (
            <Col xs={12} md={6} lg={4}>
                <a href={url} target="_blank">
                    <div className="card projectBox my-3">
                        <div className="card-body">
                            <div className="bold">{title}</div>
                            <div>{description}</div>
                        </div>
                    </div>
                </a>
            </Col>
        );
    }
}

export default ProjectCard;
