import React, {Component} from 'react';
import {Container, Col, Row} from "react-bootstrap";
import logo from '../img/fox_t.png';
import SideEffects from "./SideEffects";

class Main extends Component {
    render() {
        return (
            <div>
                <Container className="mt-5 pb-5">
                    <div className="glitch-container">
                        <div className="glitch-text">LEXIKONN</div>
                    </div>
                    <Row className="align-content-center">
                        <Col md={6} xs={12} className="text-center">
                            <SideEffects />
                        </Col>
                        <Col md={6} className="d-none d-md-block">
                            <img src={logo} className="img-fluid" alt="Logo" />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Main;